// third-party
import { FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';

// assets
import { DashboardOutlined, GoldOutlined, HomeOutlined } from '@ant-design/icons';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import firebase from 'firebase/compat';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const dashboardGenerator = (subStatus) => {
  const dashboard = {
    id: 'group-dashboard',
    type: 'group',
    children: [
      {
        id: 'search',
        title: <FormattedMessage id="Search On-Market Properties" />,
        type: 'item',
        url: '/dashboard/search',
        icon: QueryStatsIcon,
        disabled: subStatus ? false : true,
      },
      {
        id: 'comparables',
        title: <FormattedMessage id="Find Comparables" />,
        type: 'item',
        url: '/dashboard/comps',
        icon: HolidayVillageIcon,
        disabled: subStatus ? false : true,
      },
      {
        id: 'analytics',
        title: <FormattedMessage id="Analytics" />,
        type: 'item',
        url: '/dashboard/analytics',
        icon: CalculateIcon,
        disabled: subStatus ? false : true,
      },
      {
        id: 'projectPlanner',
        title: <FormattedMessage id="Project Planner" />,
        type: 'item',
        url: '/dashboard/projectPlanner',
        icon: AddHomeWorkIcon,
        disabled: subStatus ? false : true,
      }
    ]
  };
  return dashboard;
}
export default dashboardGenerator;
