import { createContext, useContext, useState, useEffect } from "react";
import { env } from 'process';
import firebase from '../contexts/firebaseClient';
import React from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Card } from "@mui/material";

export const ProjectPlannerContext = createContext(); // Add the export statement here

export const useProjectPlanner = () => {
    return useContext(ProjectPlannerContext);
};

export const ProjectPlannerProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const server = process.env.REACT_APP_SERVER_HOST

    // useEffect(() => {
    //     const storedProjects = JSON.parse(localStorage.getItem("projects"));
    //     if (storedProjects) {
    //         setProjects(storedProjects);
    //     }
    // }, []);

    useEffect(() => {
        localStorage.setItem("projects", JSON.stringify(projects));
    }, [projects]);


    //helper function for adding proprty info to existing project (&|| updating null)
    const getCalculationProperties = (type, project) => {
        switch (type) {
            case 'BRRRR':
                return {
                    propertyManagementExpenses: project.propertyManagementExpenses,
                    vacancyAndRepairCost: project.vacancyAndRepairCost,
                    monthlyInsuranceTax: project.monthlyInsuranceTax,
                    monthlyPropertyTax: project.monthlyPropertyTax,
                    monthlyPayment: project.monthlyPayment,
                    refinanceLoan: project.refinanceLoan,
                    cashflowBeforeRefi: project.cashflowBeforeRefi,
                    cashflowAfterRefi: project.cashflowAfterRefi,
                    moneyPulledOut: project.moneyPulledOut,
                    cashOnCashReturnPostRefinance: project.cashOnCashReturnPostRefinance
                };
            case 'maxBid':
                return {
                    maxOfferForProp: project.maxOfferForProp
                };
            case 'totalFixed':
                return {
                    totalInterior: project.totalInterior,
                    totalExterior: project.totalExterior,
                    totalMEPs: project.totalMEPs,
                    totalRepair: project.totalRepair
                }
            // Add more cases for other calculation types
            default:
                return {};
        }
    };


    const addProject = async (project, index, type, projectId) => {
        console.log("ADDING TO PROJECT");
        return new Promise((resolve, reject) => {
            (async () => {
                var projectData = {
                    ...project,
                    ...(type && {
                        calculations: {
                            calculationsConstructor: {},
                            ...project.calculations,
                            [type]: {
                                ...project.calculations?.[type],
                                ...getCalculationProperties(type, project),
                            },
                        },
                    }),
                    comparables: {
                        ...project.comparables,
                    },
                };
                // 819ca7a8a1msh29baa699c0aff90p16139djsn0b60c3bd12ef

                try {
                    const options = {
                        method: 'GET',
                        url: 'https://us-real-estate.p.rapidapi.com/v3/property-detail',
                        params: {
                            property_id: `${projectData.property_id}`,
                        },
                        headers: {
                            'X-RapidAPI-Key': '819ca7a8a1msh29baa699c0aff90p16139djsn0b60c3bd12ef',
                            'X-RapidAPI-Host': 'us-real-estate.p.rapidapi.com'
                        }
                    };

                    try {
                        const response = await axios.request(options);
                        console.log(response.data);
                        const propDetailed = await response.data;
                        const propDesc = propDetailed.data.description.text;
                        // Add propDesc to projectData

                        // Assuming the photos are located here
                        const photos = propDetailed.data.photos;

                        // Mapping the photos into a new array
                        const propPhotos = photos.map(photo => {
                            return photo.href;
                        });


                        projectData = {
                            ...projectData,
                            propDesc: propDesc,
                            propPhotos: propPhotos
                        };
                    } catch (error) {
                        console.error(error);

                    }

                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }


                try {
                    if (projectId) {
                        // Update existing project
                        const response = await fetch(`${server}/server/projects/${projectId}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                uid: firebase.default.auth().currentUser.uid,
                                projectData: projectData
                            })
                        });

                        if (!response.ok) {
                            throw new Error('Error updating project');
                        }

                        const updatedProject = await response.json();

                        setProjects((prevProjects) => {
                            const updatedProjects = [...prevProjects];
                            const projectIndex = updatedProjects.findIndex((p) => p.id === projectId);
                            updatedProjects[projectIndex] = {
                                ...updatedProjects[projectIndex],
                                ...updatedProject,
                            };
                            return updatedProjects;
                        });
                        resolve(updatedProject);
                    } else {
                        // Create new project
                        const response = await fetch(`${server}/server/projects`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                uid: firebase.default.auth().currentUser.uid,
                                projectData: projectData
                            })
                        });
                        const responseData = await response.json();

                        if (response.status === 403) {
                            if (responseData && responseData.error === 'Project limit reached') {
                                // Project limit reached, show an alert
                                alert("Project limit has been reached");
                            } else {
                                // Another type of error occurred, handle it
                                throw new Error(responseData.error || 'Error creating project');
                            }
                            reject(new Error("Something prevented the project being added"))
                        }

                        const createdProject = responseData;
                        console.log("created Project", createdProject)

                        setProjects((prevProjects) => {
                            if (typeof index !== 'undefined') {
                                const updatedProjects = [...prevProjects];
                                updatedProjects[index] = {
                                    ...updatedProjects[index],
                                    ...createdProject,
                                };
                                return updatedProjects;
                            } else {
                                return [...prevProjects, createdProject];
                            }
                        });
                        resolve(createdProject);
                    }
                } catch (error) {
                    console.error('Error creating/updating project:', error);
                    reject(error);
                }
            })();
        });

    };

    //handling the calculations object in the project db
    const handleUpdateProjectCalc = async (projectId, updatedData, section) => {
        try {
            let updatedProjectData;

            if (section === 'BRRRRR') {
                updatedProjectData = updatedData;
            } else if (section === 'maxBid') {
                updatedProjectData = updatedData;
            } else if (section === 'totalFixed') {
                updatedProjectData = updatedData;
            } else {
                throw new Error('Invalid section for update');
            }
            // Send a request to the API to update the existing project with new data
            const response = await fetch(`${server}/server/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: firebase.default.auth().currentUser.uid,
                    updatedProjectData: updatedProjectData,
                    section: section,
                })
            });


            if (!response.ok) {
                throw new Error('Error updating project');
            }

            const updatedProject = await response.json();

            // Update the project in your local state
            setProjects((prevProjects) => {
                const updatedProjects = prevProjects.map((project) => {
                    if (project.id === projectId) {
                        return {
                            ...project,
                            ...updatedProject,
                        };
                    }
                    return project;
                });
                return updatedProjects;
            });
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };


    const handleUpdateProjectComps = async (projectId, newComps) => {
        const uid = firebase.auth().currentUser.uid;

        try {
            // Send a request to the API to update the comps data
            const response = await fetch(`${server}/server/projects/${projectId}/comps`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid, newComps }),
            });

            if (!response.ok) {
                throw new Error('Error updating comps');
            }

            // Update the local state with the new comps data
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project.id === projectId ? { ...project, comparables: newComps } : project
                )
            );
        } catch (error) {
            console.error('Error updating comps:', error);
        }
    };





    // we have to add the local session storage
    //deleting from the project array locally
    const deleteProject = async (projectId) => {
        try {
            const uid = firebase.auth().currentUser.uid;

            // Send a request to the API to delete the project
            const response = await fetch(`${server}/server/projects/${projectId}/${uid}`, {
                method: "DELETE",
            });

            if (!response.ok) {
                throw new Error("Error deleting project");
            }

            console.log("Successfully deleted project with id:", projectId);

            // Update the local state to reflect the project's deletion
            setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));

        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };





    return (
        <ProjectPlannerContext.Provider value={{ projects, addProject, handleUpdateProjectCalc, handleUpdateProjectComps, deleteProject }}>
            {children}
            <div>
                {projects.map((project, index) => (
                    <Card key={index}>
                        {/* Render project details here */}
                    </Card>
                ))}
            </div>
        </ProjectPlannerContext.Provider>
    );
};

ProjectPlannerProvider.propTypes = {
    children: PropTypes.node,
};
