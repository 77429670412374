import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItemGenerator from 'menu-items/components';
import firebase from 'firebase/compat';

const Navigation = ({ searchValue }) => {
  const [subStatus, setSubStatus] = useState(null);
  const currentUser = firebase.auth().currentUser;
  const db = firebase.firestore();

  useEffect(() => {
    const getSubBoolean = async () => {
      if (currentUser) {
        const userId = currentUser.uid;
        try {
          const docSnapshot = await db.collection('customers').doc(userId).get();
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            const subscriptionStatus = userData.subscriptionStatus;

            setSubStatus(subscriptionStatus);
          }
        } catch (error) {
          console.error("Error fetching user's subscriptionStatus: ", error);
        }
      }
    }

    getSubBoolean();
  }, [currentUser, db]);

  const generatedMenuItems = menuItemGenerator(subStatus);

  let filteredMenuItems = [];

  if (!searchValue) {
    filteredMenuItems = generatedMenuItems.items;
  } else {
    generatedMenuItems.items.forEach((parentMenu) => {
      const matchedChildren = parentMenu.children?.filter(child => child.search?.trim().toLowerCase().includes(searchValue));

      if (matchedChildren && matchedChildren.length > 0) {
        filteredMenuItems.push({
          ...parentMenu,
          children: matchedChildren
        });
      }
    });
  }

  const navGroups = generatedMenuItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 1 }}>{navGroups}</Box>;
};

Navigation.propTypes = {
  searchValue: PropTypes.string
};

export default Navigation;
