// third-party
import { FormattedMessage } from 'react-intl';
import React from 'react';


// assets
import { CalendarOutlined, CustomerServiceOutlined, MessageOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SendIcon from '@mui/icons-material/Send';
// icons
const icons = { CalendarOutlined, CustomerServiceOutlined, MessageOutlined, ShoppingCartOutlined, UserOutlined };

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //


const applications = {

  id: 'user',
  // title: <FormattedMessage id="Uses" />,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      icon: AccountBoxIcon,
      url: '/apps/profile'
    },
    {
      id: 'contact us',
      title: <FormattedMessage id="Contact Us" />,
      icon: SendIcon,
      type: 'item',
      url: '/contactUs'
    }
  ]

};

export default applications;
