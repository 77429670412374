import applications from './applications';
import dashboardGenerator from './dashboard';

const menuItemsGenerator = (subStatus) => {
  const dashboard = dashboardGenerator(subStatus);
  return {
    items: [dashboard, applications]
  };
}

export default menuItemsGenerator;
