import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - dashboard
const SearchPage = Loadable(lazy(() => import('pages/dashboard/search')));
const CompsPage = Loadable(lazy(() => import('pages/dashboard/comps')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
const ProjectPlanner = Loadable(lazy(() => import('pages/dashboard/projectPlanner')));
const ContactUs = Loadable(lazy(() => import('pages/contactUS/contactUs')));
const Blog = Loadable(lazy(() => import('pages/blog/index')));
const BlogPage = Loadable(lazy(() => import('pages/blog/blogPost')));

// render - applications

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
//const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

const ProfileUserCard = Loadable(lazy(() => import('pages/apps/profiles/user-card')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const PricingPage = Loadable(lazy(() => import('pages/pricing/index')));

// ad page
const ZipListAD = Loadable(lazy(() => import('pages/ad-landing/zipList')))
const SubDiscount = Loadable(lazy(() => import('pages/ad-landing/subDiscount')))


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'search',
              element: <SearchPage />
            },
            {
              path: 'comps',
              element: <CompsPage />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            },
            {
              path: 'projectPlanner',
              element: <ProjectPlanner />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'profile',
              element: <AccountTabPersonal />
            },
          ]
        },
        {
          path: 'contactUs',
          element: <ContactUs />
        },
        {
          path: 'pricing',
          element: <PricingPage />
        },
        {
          path: 'blog',
          element: <Blog />,
          children: [
            {
              path: ':id',
              element: <BlogPage />
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    },
    {
      path: 'high-ROI-zipcode',
      element: <ZipListAD />
    },
    {
      path: 'thank-you-page',
      element: <SubDiscount />
    },
  ]
};

export default MainRoutes;
