import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
// third-party
import firebase from 'firebase/compat/app';
// import database from 'firebase/compat/database';
import 'firebase/compat/auth';
import { getDatabase, set, ref } from 'firebase/database';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import 'firebase/database';


const apiKey = process.env.REACT_APP_FIREBASE_KEY
const appID = process.env.REACT_APP_FIREBASE_APPID
const auth = process.env.REACT_APP_FIREBASE_AUTH
const dbURL = process.env.REACT_APP_FIREBASE_DBURL
const projID = process.env.REACT_APP_FIREBASE_PROJID
const Stg = process.env.REACT_APP_FIREBASE_STOARGE
const msgID = process.env.REACT_APP_FIREBASE_MSGID

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: auth,
  databaseURL: dbURL,
  projectId: projID,
  storageBucket: Stg,
  messagingSenderId: msgID,
  appId: appID
};

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = getDatabase();

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email,
                name: user.displayName,
                role: ''
              }
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  // const writeUserData = (email, firstname, lastname) => {
  //   set(ref(db, 'users/' + firebase.auth().currentUser.uid), {
  //     email: email,
  //     credit: 10,
  //     firstname: firstname,
  //     lastname: lastname,
  //     membership: 'Free'
  //   });
  // };

  // const firebaseRegister = async (email, password, firstname, lastname) =>
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(() => {
  //       writeUserData(email, firstname, lastname);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // const firebaseRegister = async (email, password, firstname, lastname) => {
  //   try {
  //     await firebase.auth().createUserWithEmailAndPassword(email, password);
  //     writeUserData(email, firstname, lastname);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseTwitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => { };
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        //firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => { },
        firebaseGoogleSignIn,
        firebaseTwitterSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
