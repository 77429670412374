import React, { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import useConfig from 'hooks/useConfig';
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {/* {!matchesXs && megaMenu} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification />
      <Message /> */}
      {/* <Customization /> */}
      {!matchesXs && <Profile />}
      {/* {matchesXs && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
