import React, { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

const AuthHomepage = Loadable(lazy(() => import('pages/admin/index')));
const NewTools = Loadable(lazy(() => import('pages/admin/newTools')));

const AuthRoutes = {
    path: '/',
    children: [
        {
            path: 'admin',
            children: [
                {
                    path: 'welcome',
                    element: < AuthHomepage />
                },
                {
                    path: 'new-tools',
                    element: < NewTools />
                },
                {
                    path: 'company-vision',
                    element: < AuthHomepage />
                },
                {
                    path: 'careers',
                    element: < AuthHomepage />
                },

            ]
        }
    ]
}

export default AuthRoutes;