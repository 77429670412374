import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import logo from '../../assets/Logo/wordmark/propagate-wordmark.svg';
// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {

  return (

    <img src={logo} alt="logo" style={{ width: '170px', height: '100px' }} />

  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
