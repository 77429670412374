import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const apiKey = process.env.REACT_APP_FIREBASE_KEY
const appID = process.env.REACT_APP_FIREBASE_APPID
const auth = process.env.REACT_APP_FIREBASE_APPID
const dbURL = process.env.REACT_APP_FIREBASE_APPID
const projID = process.env.REACT_APP_FIREBASE_APPID
const Stg = process.env.REACT_APP_FIREBASE_APPID
const msgID = process.env.REACT_APP_FIREBASE_APPID

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: auth,
    databaseURL: dbURL,
    projectId: projID,
    storageBucket: Stg,
    messagingSenderId: msgID,
    appId: appID
};

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;

// Additional export for storage
export const storage = firebase.storage();