import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItemGenerator from 'menu-items'; // Assuming this path is correct
import firebase from 'firebase/compat';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const [subStatus, setSubStatus] = useState(null);
  const currentUser = firebase.auth().currentUser;
  const db = firebase.firestore();

  useEffect(() => {
    const getSubBoolean = async () => {
      if (currentUser) {
        const userId = currentUser.uid;
        try {
          const docSnapshot = await db.collection('customers').doc(userId).get();
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            const subscriptionStatus = userData.subscriptionStatus;

            setSubStatus(subscriptionStatus);
          }
        } catch (error) {
          console.error("Error fetching user's subscriptionStatus: ", error);
        }
      }
    }

    getSubBoolean();
  }, [currentUser]);

  const generatedMenuItems = menuItemGenerator(subStatus); // Use the new menu item generator function

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navGroups = generatedMenuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>
      {navGroups}
    </Box>
  );
};

export default Navigation;
