import { Link as RouterLink } from 'react-router-dom';
import { React, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import './Footer.css'

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);  // updates the state variable when the input changes
  }

  // Send form data to the server
  const handleSubmit = (e) => {
    e.preventDefault();

    const server = process.env.REACT_APP_SERVER_HOST
    fetch(`${server}/server/contact-us/add-CRM`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Form submitted successfully');
        } else {
          console.error('Error submitting form');
        }
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <footer className="footer" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <div className="footer-left">
          <h2 style={{ marginBottom: '0px' }}>Stay Connected:</h2>
          <div className='email-button-wrap'>
            <input id='email' type="email" placeholder="Enter Email Address" value={email} onChange={handleChange} />
            <button className='footer-button' onClick={handleSubmit}>Subscribe</button>
          </div>
        </div>


        <div className="footer-right">
          <div className="footer-links" >
            <a href="/">Home</a>
            <a href="/pricing">Pricing</a>
            <a href="/about-us">Our Story</a>
            <a href="/contactUs">Contact</a>
            <a href={isMobile ? "/" : "/dashboard/search"}>My Dashboard</a>
          </div>
          <div className="footer-links">
            <a href="#">Legal</a>
            <a href="#">Terms & Conditions</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Investor Privacy Notice</a>
            <a href="#">Cookies Policy</a>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer;
