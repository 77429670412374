import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing/index')));
const PricingPage = Loadable(lazy(() => import('pages/pricing/index')));
const AboutUsPage = Loadable(lazy(() => import('pages/about/index')));
const ContactUs = Loadable(lazy(() => import('pages/contactUS/contactUs')));
const Blog = Loadable(lazy(() => import('pages/blog/index')));
const BlogPost = Loadable(lazy(() => import('pages/blog/blogPost')));
const ZipListAD = Loadable(lazy(() => import('pages/ad-landing/zipList')))
const SubDiscount = Loadable(lazy(() => import('pages/ad-landing/subDiscount')))
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        },
        {
          path: 'pricing',
          element: <PricingPage />
        },
        {
          path: 'about-us',
          element: <AboutUsPage />
        },
        {
          path: 'contactUs',
          element: <ContactUs />
        },
        {
          path: 'blog',
          element: <Blog />,
        },
        {
          path: 'blog/:id/:slug',
          element: <BlogPost />
        },
        {
          path: 'high-ROI-zipcode',
          element: <ZipListAD />
        },
        {
          path: 'thank-you-page',
          element: <SubDiscount />
        },
      ]
    },
    LoginRoutes,
    MainRoutes,
    AuthRoutes,
  ]);
}
